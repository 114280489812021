import { Dialog } from '@/elements'
import { Button, Flex, Slot, Text } from '@/primitives'
import { I18n } from 'aws-amplify'

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  data
}) => {
  const handleConfirm = e => {
    e.preventDefault()
    onConfirm(data)
  }

  const handleCancel = e => {
    e.preventDefault()
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {title}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text as='p'>{message}</Text>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis='space-between'>
          <Button variant='page' size='small' onClick={handleCancel}>
            {I18n.get('Cancel')}
          </Button>
          <Button variant='error' size='small' onClick={handleConfirm}>
            {I18n.get('Confirm')}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default ConfirmationModal
