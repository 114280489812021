import strings from '../../Strings'

export const getChartOptions = ({
  data,
  selectedMetric,
  showDelta,
  metric,
  sectionAName,
  sectionBName,
  colors = {
    sectionA: '#3b82f6',
    sectionB: '#f97316',
    delta: '#6366f1'
  }
}) => {
  const series = showDelta
    ? [
        {
          name: `${sectionAName} - ${sectionBName}`,
          data: data.map(item => ({
            x: new Date(item.timestamp).getTime(),
            y: Number(
              (
                item[`sectionA_${selectedMetric}`] -
                item[`sectionB_${selectedMetric}`]
              ).toFixed(2)
            )
          })),
          color: colors.delta
        }
      ]
    : [
        {
          name: sectionAName,
          data: data.map(item => ({
            x: new Date(item.timestamp).getTime(),
            y: Number(item[`sectionA_${selectedMetric}`].toFixed(2))
          })),
          color: colors.sectionA
        },
        {
          name: sectionBName,
          data: data.map(item => ({
            x: new Date(item.timestamp).getTime(),
            y: Number(item[`sectionB_${selectedMetric}`].toFixed(2))
          })),
          color: colors.sectionB
        }
      ]

  return {
    chart: {
      type: 'line',
      zoomType: 'x',
      height: 300,
      backgroundColor: 'transparent'
    },
    title: {
      text: null
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: strings.dateAndTime
      }
    },
    yAxis: {
      title: {
        text: `${metric.title} (${metric.unitLabel})`
      }
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom'
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      }
    },
    series,
    tooltip: {
      shared: true,
      crosshairs: true,
      xDateFormat: '%Y-%m-%d %H:%M',
      valueDecimals: 2,
      valueSuffix: ` ${metric.unitLabel}`
    }
  }
}

export const findZoneById = (zoneId, zoneHierarchy) => {
  if (!zoneHierarchy || !zoneId) {
    return null
  }

  if (zoneHierarchy.id === zoneId) {
    return zoneHierarchy
  }

  if (zoneHierarchy.children) {
    const children = Object.values(zoneHierarchy.children)
    for (const child of children) {
      const result = findZoneById(zoneId, child)
      if (result) {
        return result
      }
    }
  }

  return null
}

const getDevicesFromZone = (zone, type, sensors = []) => {
  if (!zone?.devices?.length) return sensors

  for (const device of zone.devices) {
    if (
      device.sensorType.includes(type) &&
      !device.sensorType.includes('tubular')
    ) {
      sensors.push({
        value: device.id,
        label: `${device.name} (${device.sensorType})`,
        type: device.sensorType
      })
    }
  }

  return sensors
}

const collectAllSensors = (zone, type, sensors = []) => {
  if (!zone) return sensors

  getDevicesFromZone(zone, type, sensors)

  if (zone.children) {
    const children = Object.values(zone.children)
    for (const child of children) {
      collectAllSensors(child, type, sensors)
    }
  }

  return sensors
}

export const getSensorsFromZone = (zone, type) => {
  if (!zone || !type) return []
  return collectAllSensors(zone, type, [])
}
