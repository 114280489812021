import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from '@aws-amplify/core'

import { Loader } from '@/primitives'
import withAuthenticator from './Auth/WithAuthenticator'
import { Shell, Header, Footer, Content, Navigation } from './UIShell'
import { GlobalNotification, ServiceNotification } from './UIShell/Notification'
import NochCenter from './NochCenter'

import { fetchMeasurementsAndDeviceTypes } from '@/slices/deviceManagement'
import { fetchDashboards } from '@/slices/management/dashboard'
import { fetchCurrentUser } from '@/slices/management/user'
import {
  fetchZonesHierarchy,
  fetchZonesHierarchyDevices
} from '@/slices/management/hierarchy'
import { fetchSensorThresholdStatusesByRoot } from '@/slices/threshold/sensorThresholdStatus'
import { fetchSiteOfflineDevices } from '@/slices/diagnostics/deviceIsOffline'
import { showBanner, setLang } from '@/slices/util'

import { useAuth } from '@/contexts/auth-context'

import useMediaQuery from '@/hooks/useMediaQuery'
import useColorScheme from '@/hooks/useColorScheme'

import {
  getCurrentUser,
  getCurrentUserLoading,
  getUserSliceError,
  getLang,
  getSWUpdated,
  getZonesHierarchyIds,
  getSitesHierarchyIds,
  getZonesHierarchyFetched,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import Routes from '../routes'
import { fetchHelpTextsByLang } from '@/slices/fyi/helpText'
import { fetchAllOrganizationLabels } from '@/slices/management/organizationLabels'
import {
  setPanelPrimaryOpen,
  togglePanelPrimaryLock
} from '@/slices/navigation'

import { getLanguage } from '../i18n/setup'

function App() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const preferredLanguage = auth?.getCognitoPreferredLanguage()
  const openSidebar = auth?.getCognitoOpenSidebar()

  const isTablet = useMediaQuery('max-width: 768px')
  const isSmallDesktop = useMediaQuery('min-width: 1440px')

  const currentUser = getCurrentUser()
  const currentUserError = getUserSliceError()
  const currentUserLoading = getCurrentUserLoading()
  const lang = getLang()
  const swUpdated = getSWUpdated()
  const zonesHierarchyIds = getZonesHierarchyIds()
  const sitesHierarchyIds = getSitesHierarchyIds()
  const zonesHierarchyFetched = getZonesHierarchyFetched()

  const locationSelector = getNavigationLocationSelector()

  const { colorScheme } = useColorScheme()

  useEffect(() => {
    dispatch(fetchCurrentUser())
  }, [dispatch])

  useEffect(() => {
    if (preferredLanguage) {
      I18n.setLanguage(getLanguage(preferredLanguage))
      dispatch(setLang(preferredLanguage))
      if (!isTablet && openSidebar) {
        dispatch(setPanelPrimaryOpen(openSidebar))
        if (isSmallDesktop) {
          dispatch(togglePanelPrimaryLock())
        }
      }
    }
  }, [preferredLanguage])

  useEffect(() => {
    document.documentElement.dataset.theme = colorScheme
  }, [colorScheme])

  useEffect(() => {
    if (currentUser?.userName) {
      dispatch(fetchMeasurementsAndDeviceTypes())
      dispatch(fetchZonesHierarchy())
      dispatch(fetchDashboards())
    }
  }, [dispatch, currentUser?.userName])

  useEffect(() => {
    if (currentUser?.userName) {
      dispatch(fetchHelpTextsByLang({ lang }))
    }
  }, [dispatch, currentUser?.userName, lang])

  useEffect(() => {
    const organization = locationSelector.get('organization')
    if (currentUser?.userName) {
      // selected organization in navigation takes precedence
      let organizationIds = [organization?.id]

      // fallback to user's organizations if no organization is selected
      if (!organization?.id) {
        organizationIds = currentUser.organizationIds
      }
      // god mode users have no organizations so it won't fetch anything until they select an organization
      if (organizationIds.length > 0) {
        dispatch(
          fetchAllOrganizationLabels({ organizationIds, lang: lang || 'en' })
        )
      }
    }
  }, [
    dispatch,
    currentUser?.userName,
    currentUser?.organizationIds,
    locationSelector.get('organization')?.id,
    lang
  ])

  useEffect(() => {
    if (zonesHierarchyIds?.length > 0) {
      dispatch(fetchZonesHierarchyDevices({ zoneIds: zonesHierarchyIds }))
    }
  }, [dispatch, zonesHierarchyIds])

  useEffect(() => {
    if (sitesHierarchyIds?.length > 0) {
      dispatch(
        fetchSensorThresholdStatusesByRoot({ rootIds: sitesHierarchyIds })
      )
      dispatch(fetchSiteOfflineDevices({ siteIds: sitesHierarchyIds }))
    }
  }, [dispatch, sitesHierarchyIds])

  useEffect(() => {
    const interval = setInterval(() => {
      refreshCognitoUser()
    }, 1000 * 60 * 55)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (currentUserError) {
      const errorDetails = {
        show: true,
        message: currentUserError,
        type: 'error'
      }
      dispatch(showBanner(errorDetails))
    }
  }, [dispatch, currentUserError])

  async function refreshCognitoUser() {
    await auth.currentUser()
  }

  return (
    <Shell key={lang}>
      <Loader isLoading={currentUserLoading} style={{ position: 'static' }}>
        {currentUser?.userName && zonesHierarchyFetched && (
          <>
            <Content>
              <Routes />
            </Content>
            <Footer />
            <Header />
            <Navigation />
            <GlobalNotification />
            <NochCenter />
          </>
        )}
      </Loader>
      {swUpdated && <ServiceNotification />}
    </Shell>
  )
}

export default withAuthenticator(App)
