import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import ExportingModule from 'highcharts/modules/exporting'
import OfflineExportingModule from 'highcharts/modules/offline-exporting'
import ExportDataModule from 'highcharts/modules/export-data'
import { strings } from './strings'
import useColorScheme from '@/hooks/useColorScheme'
import './index.scss'
;[
  NoDataToDisplay,
  ExportingModule,
  OfflineExportingModule,
  ExportDataModule
].forEach(module => module(Highcharts))

const EXPORT_MENU_ITEMS = [
  'viewFullscreen',
  'separator',
  'downloadPNG',
  'downloadJPEG',
  'separator',
  'downloadCSV',
  'viewData'
]

const HighchartsComponent = ({
  options,
  isLoading,
  exportFileNamePrefix = 'chart-export'
}) => {
  const chartContainerRef = useRef(null)
  const chartRef = useRef(null)
  const { colorScheme } = useColorScheme()

  const isDark = useMemo(() => colorScheme === 'dark', [colorScheme])

  const darkModeStyles = useMemo(
    () => ({
      chart: {
        backgroundColor: isDark ? '#1e1e1e' : '#ffffff'
      },
      title: {
        style: { color: isDark ? '#ffffff' : '#000000' }
      },
      xAxis: {
        labels: {
          style: { color: isDark ? '#ffffff' : '#000000' }
        }
      },
      yAxis: {
        labels: {
          style: { color: isDark ? '#ffffff' : '#000000' }
        }
      },
      legend: {
        itemStyle: { color: isDark ? '#ffffff' : '#000000' }
      }
    }),
    [isDark]
  )

  const getExportFileName = useCallback(
    () =>
      `${exportFileNamePrefix}-${new Date()
        .toISOString()
        .replace(/[:.]/g, '-')}`,
    [exportFileNamePrefix]
  )

  const columnHeaderFormatter = useCallback(item => {
    if (!item) return 'Category'
    if (item instanceof Highcharts.Axis) {
      return item.options.title?.text || item.options.id || 'Values'
    }
    if (item.series) {
      return item.series.name || 'Series'
    }
    return item.name || 'Value'
  }, [])

  const chartConfig = useMemo(
    () => ({
      ...options,
      lang: strings,
      chart: {
        height: options.series?.length ? '' : 100,
        ...options.chart
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: EXPORT_MENU_ITEMS
          }
        },
        csv: {
          columnHeaderFormatter
        },
        filename: getExportFileName(),
        showTable: false,
        allowTableSorting: true,
        chartOptions: darkModeStyles
      }
    }),
    [options, darkModeStyles, columnHeaderFormatter, getExportFileName]
  )

  const initializeChart = useCallback(() => {
    if (!chartContainerRef.current) return

    if (!chartRef.current) {
      chartRef.current = Highcharts.chart(
        chartContainerRef.current,
        chartConfig
      )
    } else {
      chartRef.current.update(chartConfig, true, true)
    }

    if (chartRef.current) {
      isLoading
        ? chartRef.current.showLoading()
        : chartRef.current.hideLoading()
    }
  }, [chartConfig, isLoading])

  useEffect(() => {
    initializeChart()

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
        chartRef.current = null
      }
    }
  }, [initializeChart])

  return <div ref={chartContainerRef} className='w-full h-full' />
}

export default React.memo(HighchartsComponent)
