import { useState, useCallback, useEffect } from 'react'
import {
  getTrialComments,
  addTrialComment,
  deleteTrialComment
} from '@/api/operations/trialNotebook'
import { getUserBasicInfo } from '@/api/management/user'
import { useDispatch } from 'react-redux'
import { showBanner } from '@/slices/util'
import { I18n } from 'aws-amplify'

export const useTrialNotes = trialId => {
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const reduxDispatch = useDispatch()

  const showErrorBanner = useCallback(
    error => {
      reduxDispatch(
        showBanner({
          show: true,
          message: I18n.get(error?.message || 'Unknown error'),
          type: 'error'
        })
      )
    },
    [reduxDispatch]
  )

  const handleOperation = useCallback(
    async (operation, operationParams = {}) => {
      if (isSubmitting) return false

      try {
        setIsSubmitting(true)
        const response = await operation(operationParams)

        if (response.error) {
          showErrorBanner(response.error)
          throw response.error
        }

        return response.data
      } catch (err) {
        showErrorBanner(err)
        return false
      } finally {
        setIsSubmitting(false)
      }
    },
    [isSubmitting, showErrorBanner]
  )

  const fetchUserDetails = useCallback(async usernames => {
    const uniqueUsernames = [...new Set(usernames)]
    const userDetailsMap = {}

    await Promise.all(
      uniqueUsernames.map(async username => {
        try {
          const response = await getUserBasicInfo({ userName: username })
          if (response.user) {
            userDetailsMap[username] = response.user
          }
        } catch (err) {}
      })
    )

    return userDetailsMap
  }, [])

  const enhanceNotesWithUserDetails = useCallback(
    async notesData => {
      if (!notesData?.length) return []

      const usernames = notesData.map(note => note.createdBy)
      const userDetailsMap = await fetchUserDetails(usernames)

      return notesData.map(note => ({
        ...note,
        userDetails: userDetailsMap[note.createdBy] || null
      }))
    },
    [fetchUserDetails]
  )

  const fetchNotes = useCallback(async () => {
    if (!trialId) return

    try {
      setLoading(true)
      const response = await getTrialComments({ trialId })

      if (response.error) {
        showErrorBanner(response.error)
        setNotes([])
        return
      }

      const enhancedNotes = await enhanceNotesWithUserDetails(
        response.data || []
      )
      setNotes(enhancedNotes)
    } finally {
      setLoading(false)
    }
  }, [trialId, enhanceNotesWithUserDetails, showErrorBanner])

  const addNote = useCallback(
    async message => {
      if (!message.trim() || !trialId) return false

      const noteData = await handleOperation(addTrialComment, {
        trialId,
        message: message.trim(),
        metadata: {}
      })

      if (noteData) {
        const [enhancedNote] = await enhanceNotesWithUserDetails([noteData])
        setNotes(prevNotes => [enhancedNote, ...prevNotes])
        return true
      }

      return false
    },
    [trialId, handleOperation, enhanceNotesWithUserDetails]
  )

  const deleteNote = useCallback(
    async id => {
      if (!id) return false

      const deleted = await handleOperation(deleteTrialComment, { id })

      if (deleted) {
        setNotes(prevNotes => prevNotes.filter(note => note.id !== id))
        return true
      }

      return false
    },
    [handleOperation]
  )

  useEffect(() => {
    if (trialId) {
      fetchNotes()
    }
  }, [trialId])

  return {
    notes,
    loading,
    isSubmitting,
    addNote,
    deleteNote
  }
}
