import { I18n } from 'aws-amplify'

export const MODAL_TYPES = {
  DELETE: 'DELETE'
}

export const getModalConfig = type => {
  const configs = {
    [MODAL_TYPES.DELETE]: {
      title: I18n.get('Delete'),
      message: I18n.get(
        'Are you sure you want to delete this item? This action cannot be undone.'
      )
    }
  }

  return configs[type] || configs[MODAL_TYPES.DELETE]
}
