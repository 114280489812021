const cleanS3Url = url => {
  try {
    const urlObj = new URL(url)

    if (urlObj.hostname.includes('s3') && urlObj.search) {
      return urlObj.origin + urlObj.pathname
    }

    return url
  } catch (e) {
    return url
  }
}

export const cleanEditorContent = htmlContent => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlContent || ''

  const images = tempDiv.getElementsByTagName('img')

  Array.from(images).forEach(img => {
    const originalSrc = img.getAttribute('src')
    if (originalSrc) {
      const cleanedSrc = cleanS3Url(originalSrc)
      img.setAttribute('src', cleanedSrc)
    }
  })

  return tempDiv.innerHTML
}
