import { makeOperationsApiCall } from './utils'

const createErrorResponse = error => ({
  data: null,
  error: {
    message: error?.message || 'An unexpected error occurred',
    code: error?.code || 'UNKNOWN_ERROR',
    details: error?.details || null
  }
})

const createSuccessResponse = data => ({
  data,
  error: null
})

const QUERIES = {
  GET_TRIAL_COMMENTS: /* GraphQL */ `
    query getTrialComments($trialId: String!) {
      getTrialComments(trialId: $trialId) {
        id
        message
        createdBy
        createdAt
        updatedAt
        metadata
      }
    }
  `,

  ADD_TRIAL_COMMENT: /* GraphQL */ `
    mutation addTrialComment(
      $trialId: String!
      $message: String!
      $metadata: GraphQLJSON
    ) {
      addTrialComment(
        trialId: $trialId
        message: $message
        metadata: $metadata
      ) {
        id
        message
        createdBy
        createdAt
        metadata
      }
    }
  `,

  EDIT_TRIAL_COMMENT: /* GraphQL */ `
    mutation editTrialComment(
      $id: String!
      $message: String!
      $metadata: GraphQLJSON
    ) {
      editTrialComment(id: $id, message: $message, metadata: $metadata) {
        id
        message
        createdBy
        updatedAt
        metadata
      }
    }
  `,

  DELETE_TRIAL_COMMENT: /* GraphQL */ `
    mutation deleteTrialComment($id: String!) {
      deleteTrialComment(id: $id) {
        id
        message
        createdBy
      }
    }
  `
}

const validateParams = (params, requiredFields) => {
  const missingFields = requiredFields.filter(field => !params?.[field])
  if (missingFields.length > 0) {
    throw new Error(`Missing required fields: ${missingFields.join(', ')}`)
  }
}

const validateMessage = message => {
  if (!message?.trim()) {
    throw new Error('Message cannot be empty')
  }
}

const handleGraphQLErrors = error => {
  if (error) {
    return createErrorResponse({
      message: error || 'An error occurred',
      code: 'GRAPHQL_ERROR'
    })
  }
  return null
}

const handleCommonErrors = error => {
  if (
    error?.message?.includes('Missing required fields') ||
    error?.message?.includes('Message cannot be empty')
  ) {
    return createErrorResponse({
      message: error.message,
      code: 'VALIDATION_ERROR'
    })
  }
  return createErrorResponse(error)
}

const executeOperation = async ({ query, params, queryKey }) => {
  const { data, error } = await makeOperationsApiCall({
    query,
    params,
    queryKey,
    resultKey: 'data'
  })

  const graphQLError = handleGraphQLErrors(error)
  if (graphQLError) return graphQLError
  return createSuccessResponse(data)
}

export const getTrialComments = async params => {
  try {
    validateParams(params, ['trialId'])
    return executeOperation({
      query: QUERIES.GET_TRIAL_COMMENTS,
      params,
      queryKey: 'getTrialComments'
    })
  } catch (error) {
    return handleCommonErrors(error)
  }
}

export const addTrialComment = async params => {
  try {
    validateParams(params, ['trialId', 'message'])
    validateMessage(params.message)
    return executeOperation({
      query: QUERIES.ADD_TRIAL_COMMENT,
      params,
      queryKey: 'addTrialComment'
    })
  } catch (error) {
    return handleCommonErrors(error)
  }
}

export const editTrialComment = async params => {
  try {
    validateParams(params, ['id', 'message'])
    validateMessage(params.message)
    return executeOperation({
      query: QUERIES.EDIT_TRIAL_COMMENT,
      params,
      queryKey: 'editTrialComment'
    })
  } catch (error) {
    return handleCommonErrors(error)
  }
}

export const deleteTrialComment = async params => {
  try {
    validateParams(params, ['id'])
    return executeOperation({
      query: QUERIES.DELETE_TRIAL_COMMENT,
      params,
      queryKey: 'deleteTrialComment'
    })
  } catch (error) {
    return handleCommonErrors(error)
  }
}
