import { createContext, useContext, useState } from 'react'
import ConfirmationModal from './ConfirmationModal'
import { getModalConfig } from './config'

const ModalContext = createContext(null)

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
    data: null,
    onConfirm: null
  })

  const showModal = ({ type, data, onConfirm }) => {
    setModalState({
      isOpen: true,
      type,
      data,
      onConfirm
    })
  }

  const hideModal = () => {
    setModalState({
      isOpen: false,
      type: null,
      data: null,
      onConfirm: null
    })
  }

  const handleConfirm = data => {
    modalState.onConfirm?.(data)
    hideModal()
  }

  const modalConfig = modalState.type ? getModalConfig(modalState.type) : {}

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ConfirmationModal
        isOpen={modalState.isOpen}
        onClose={hideModal}
        onConfirm={handleConfirm}
        data={modalState.data}
        {...modalConfig}
      />
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
