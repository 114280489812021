import Box from '@/primitives/Box'
import Button from '@/primitives/Button'
import FlexV2 from '@/primitives/FlexV2'
import RichEditor from '@/components/Shared/RichEditor'
import strings from '@/components/TrialModeView/Strings'

export const NoteEditor = ({
  value,
  onChange,
  isSubmitting,
  onSubmit,
  trialId
}) => (
  <Box>
    <RichEditor
      value={value}
      onChange={onChange}
      disabled={isSubmitting}
      placeholder={strings.notePlaceholder}
      parentId={trialId}
    />
    <Box style={{ marginTop: 20 }}>
      <FlexV2 alignMainAxis='flex-end'>
        <Button
          variant='primary'
          disabled={!value.trim() || isSubmitting}
          onClick={onSubmit}
        >
          {isSubmitting ? strings.addingNote : strings.addNoteLabel}
        </Button>
      </FlexV2>
    </Box>
  </Box>
)
