export const formatDate = dateString => {
  if (!dateString) return ''

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }

  try {
    return new Date(+dateString).toLocaleDateString(navigator.language, options)
  } catch {
    return ''
  }
}
