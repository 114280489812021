import { AWS_EDITOR_UPLOAD_BUCKET_PREFIX, editorS3Service } from './config'

export class S3UploadAdapter {
  constructor(loader, parentId) {
    this.loader = loader
    this.parentId = parentId
  }

  async upload() {
    try {
      const file = await this.loader.file
      const path = this._generatePath(file)
      const url = await editorS3Service.uploadFile(file, path)

      return {
        default: url
      }
    } catch (error) {
      throw error
    }
  }

  abort() {}

  _generatePath(file) {
    const date = new Date().toISOString().split('T')[0]
    const timestamp = Date.now()
    const randomString = Math.random().toString(36).substring(2, 8)
    const safeFileName = file.name.toLowerCase().replace(/[^a-z0-9.]/g, '-')

    return this.parentId
      ? `${AWS_EDITOR_UPLOAD_BUCKET_PREFIX}/${this.parentId}/${date}/${timestamp}-${randomString}-${safeFileName}`
      : `${AWS_EDITOR_UPLOAD_BUCKET_PREFIX}/${date}/${timestamp}-${randomString}-${safeFileName}`
  }
}
