import Box from '@/primitives/Box'
import FlexV2 from '@/primitives/FlexV2'
import Button from '@/primitives/Button'
import HighchartsComponent from '@/components/Shared/HighchartsComponent'
import { getChartOptions } from './utils'
import { getDatasetLoading } from '@/reducers/selectors'

const HistoricalView = ({
  metrics,
  selectedMetric,
  selectedMetricObj,
  onMetricSelect,
  transformedData,
  showDelta,
  sectionAName,
  sectionBName
}) => {
  const isDataSetLoading = getDatasetLoading()

  return (
    <>
      <FlexV2
        axisGap={200}
        style={{ marginLeft: '1rem', marginBottom: '1rem' }}
      >
        {metrics.map(metric => (
          <Button
            key={metric.id}
            variant={selectedMetric === metric.id ? 'primary' : 'outline'}
            onClick={() => onMetricSelect(metric.id)}
            iconBefore={metric.icon}
          >
            {metric.title}
          </Button>
        ))}
      </FlexV2>

      <Box>
        <HighchartsComponent
          options={getChartOptions({
            data: transformedData,
            selectedMetric,
            showDelta,
            metric: selectedMetricObj,
            sectionAName,
            sectionBName
          })}
          isLoading={isDataSetLoading}
          exportFileNamePrefix={selectedMetric}
        />
      </Box>
    </>
  )
}

export default HistoricalView
