import { makeOperationsApiCall } from './utils'

export const getTrials = async params => {
  const query = /* GraphQL */ `
    query getTrials($zoneId: String!) {
      getTrials(zoneId: $zoneId) {
        id
        name
        startDate
        endDate
        status
        zone {
          name
          id
        }
        roomA {
          id
          name
        }
        roomB {
          id
          name
        }
      }
    }
  `
  try {
    const { data } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getTrials',
      resultKey: 'data'
    })
    return data
  } catch (err) {
    return err
  }
}

export const getTrial = async params => {
  const query = /* GraphQL */ `
    query getTrials($id: String!) {
      getTrials(id: $id) {
        id
        name
        startDate
        endDate
        status
        zone {
          name
          id
        }
        roomA {
          id
          name
        }
        roomB {
          id
          name
        }
      }
    }
  `

  try {
    const { data } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getTrials',
      resultKey: 'data'
    })
    if (!data || !Array.isArray(data)) {
      throw new Error('Invalid response format')
    }
    return data.length ? data[0] : null
  } catch (err) {
    return { data: {}, error: err.message }
  }
}
